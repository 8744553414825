let applicationState = {
  isDeletingSinglePage: false,
  isResponsiveEditor: false,
  isADI: false,
  integratedAppsMap: {},
};

const setIsDeletingSinglePage = (isDeleting) => {
  applicationState = {
    ...applicationState,
    isDeletingSinglePage: isDeleting,
  };
};

const setIsResponsiveEditor = (isResponsiveEditor) => {
  applicationState = {
    ...applicationState,
    isResponsiveEditor,
  };
};

const setIsADI = (isADI) => {
  applicationState = {
    ...applicationState,
    isADI,
  };
};

const setStaticsBaseUrl = (staticsBaseUrl) => {
  applicationState = {
    ...applicationState,
    staticsBaseUrl,
  };
};

const setIntegratedApps = (integratedAppsMap) => {
  applicationState = {
    ...applicationState,
    integratedAppsMap,
  };
};

const getIsDeletingSinglePage = () => applicationState.isDeletingSinglePage;
const getIsResponsiveEditor = () => applicationState.isResponsiveEditor;
const getIsADI = () => applicationState.isADI;
const getStaticsBaseUrl = () => applicationState.staticsBaseUrl;
const getAllIntegratedApps = () => applicationState.integratedAppsMap || {};
const getVerticalsApps = (verticalAppDefId) => applicationState.integratedAppsMap[verticalAppDefId] || [];
const removeVerticalsApps = (verticalAppDefId) => {
  delete applicationState.integratedAppsMap[verticalAppDefId];
};

export {
  setIsDeletingSinglePage,
  getIsDeletingSinglePage,
  setIsResponsiveEditor,
  getIsResponsiveEditor,
  setIsADI,
  getIsADI,
  setStaticsBaseUrl,
  getStaticsBaseUrl,
  setIntegratedApps,
  getAllIntegratedApps,
  removeVerticalsApps,
  getVerticalsApps,
};
